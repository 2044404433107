import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import styles from './style.css';
import './App.css';
import { API_URL } from './config';
import axios from 'axios';

function App() {
  const [weight, setWeight] = useState(0);
  const [displayWeight, setDisplayWeight] = useState('0,0');
  const [todayDisplayWeight, setTodayDisplayWeight] = useState(null);

  async function getWeight() {
    try {
      const response = await axios.get(`${API_URL}weight`);

      if (response.status === 200) {
        setTodayDisplayWeight(convertNumberToWeightInKg(response.data.weight));
        console.log(response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const input = (number) => {
    if (weight == 0) {
      setWeight(number);
    } else {
      setWeight((weight * 10) + number);
    }
  };

  const eraseNumber = () => {
    if (weight > 0) {
      const weightInString = weight.toString();

      setWeight(weightInString.substring(
          0,
          weightInString.length - 1
      ));
    }
  };

  const convertNumberToWeightInKg = (number) => {
    if (number < 10 && number > 0) {
        return '0,'+number;
    } else if (number == 0) {
        return '0,0';
    } else {
        return Math.floor(number / 10)+','+number % 10;
    }
  }

  async function submit() {
    try {
      const data = {
        weight: weight
      };

      const response = await axios.post(`${API_URL}weight`, JSON.stringify(data));

      if (response.status === 200) {
        setWeight(0);
        setDisplayWeight('0,0');
        getWeight();
        alert(response.data.message);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    setDisplayWeight(convertNumberToWeightInKg(weight));
    getWeight();
  }, [weight]);

  return (
    <div className="App">
      <div id="weight-input">
        <div className={`center`}>
          <div id="weight-to-store">{displayWeight}</div>
        </div>
        <div className={`center`}>
          <div id="weight-unit">kg</div>
        </div>
      </div>
      {todayDisplayWeight != '0,0' ? (
        <p className={`enter-info`}>You already entered weight today - {todayDisplayWeight} kg.</p>
      ) : null}
      <div id="keyboard">
        {Array.from({ length: 9 }, (_, i) => i + 1).map((i) => (
          <div className={`tile`} key={i}>
            <input
              className={`input-${i}`}
              type="button"
              value={i}
              onClick={() => input(i)}
            />
          </div>
        ))}
        <div className={`tile`}>
          <input
            className={`input-0`}
            type="button"
            value="0"
            onClick={() => input(0)}
          />
        </div>
        <div className={`tile`}>
          <input
            className={`input-erase`}
            type="button"
            value="Erase"
            onClick={() => eraseNumber()}
          />
        </div>
        <div className={`tile`}>
          <input
            className={`cta`}
            type="submit"
            value="Save"
            onClick={() => submit()}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
